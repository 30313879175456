// CITIC

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "CITIC Pacific Mining Management Pty Ltd",
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          AMP Signature Super – with Citic Pacific Mining & Cape Preston Port Company
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            By joining the company Superannuation fund, you benefit from group
            discount rates on fees and insurances. This means you pay much
            less than you would pay in a personal or individual plan.
          </p>
          <p>
            This website provides all the information you need around the <strong>CITIC Pacific Mining Superannuation Fund</strong>. There are helpful super tools and calculators, and information on other services we offer if you require additional support with your financial well-being.
          </p>
          
          <div class='vivo'>
            <h4>Getting started with Vivo</h4>
            <p>
              <a href='https://vivovirtualcare.com.au/' target='_blank'>Vivo Virtual Care</a> gives you and your immediate family unlimited access to confidential online services, including Expert Medical Opinion, Mental Health Navigator, Fitness Consult, and Nutrition Consult. Vivo services are available at no additional cost to those insured by MLC Life Insurance*.
            </p>
            <p>           
              Simply get in touch with our team, by emailing <a href='mailto:support@vivowellbeing.com.au'>support@vivowellbeing.com.au</a>. Upon receiving your email, we will reach out to discuss how we can help you.
            </p>
            <small>
              *The services that are available for your access depends on and may be limited by arrangements we have with the service providers we have partnered with and/or agreements we have entered into with the owner of the policy (such as the trustee or employer for group insurance policies).
            </small>
          </div>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p><b>Fees (per annum)</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Member Fee:
              <b>$78 (paid by Citic)</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Administration Fee:
              <b>0.19%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Trustee Fee:
              <b>0.015%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Investment Fees:
              <b>0.26%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Other MySuper Investment Costs: 
              <b>0.10% - 0.35%</b>
            </li>
          </ul>
          <small>
            Investment Fees and Costs are deducted from investment returns prior to calculation of daily unit prices. For full fee disclosure refer to the Fund Product Disclosure Statement (PDS).
          </small>
          <p class='mt-2'>
            <b>Insurances</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Death & Total Permanent Disability:</strong>
              Cover is based on your salary and age (paid by Citic).
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Salary Continuance:</strong>
              Covers you for 75% of salary, 90 day waiting period, 2 year benefit period.
            </li>
          </ul>
          <p>
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              AMP MySuper Lifecycle
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="https://www.amp.com.au/amp/performance-and-unit-prices/superannuation-funds/signaturesuper" target="_blank">AMP Signature Super</a>
            </li>
          </ul>
        </div>
      </div>
      
      `,
    pdfs: [
      {
        title: "Join the CPM fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "CPM-New_Employee_Pack-October_2023.pdf",
      },
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the CPPC Fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "CPPC-New_Employee_Pack-October_2023.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Investment Selection",
        description: "Do you want to change your investment choice?",
        type: "form",
        url: "AMP_Investment_option_form_03032023.pdf",
      },
      {
        title: "Beneficiary Nomination",
        description:
          "Do you need to nominate a beneficiary on your super fund?",
        type: "form",
        url: "AMP_Beneficiary_Form_11102023.pdf",
      },
      {
        title: "Investment Guide",
        description: "Additional information on your super fund.",
        type: "info",
        url: "AMP_SS_Investment_Guide_01042024.pdf",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "info",
        url: "AMP_pds.pdf",
      },
      {
        title: "Member Guide",
        description: "Additional information on your super fund.",
        type: "info",
        url: "AMP_SS_Member_Guide_01042024.pdf",
      },
      {
        title: "AMP Signature Super Insurance Guide",
        description: "In-depth information on your super fund insurances.",
        type: "info",
        url: "AMP_SS_Insurance_Guide_MLC.pdf",
      },
    ],
    optInLink: "https://secure.amp.com.au/ddc/public/ui/pmif/",
    teamMembers: [
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "dianna.png",
        name: "Dianna Gilbert",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "dianna_gilbert@ajg.com.au",
      },
    ],
    footerClientName: "CITIC Pacific Mining Management Pty Ltd",
  },
  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
